@tailwind base;
@tailwind components;
@tailwind utilities;

#wave-body {
  background: linear-gradient(315deg, rgb(236, 239, 241) 0%, rgb(207, 216, 220) 50%, rgb(176, 190, 197) 100%);
  animation: gradient 15s ease infinite;
  background-size: 400% 400%;
  background-attachment: fixed;
}

@keyframes gradient {
  0% {
      background-position: 0% 0%;
  }
  50% {
      background-position: 100% 100%;
  }
  100% {
      background-position: 0% 0%;
  }
}
